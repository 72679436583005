const RoleName = {
  OrganizationAdmin: 'OrganizationAdmin',
  BookingManager: 'BookingManager',
  CustomerAdmin: 'CustomerAdmin',
  CustomerManager: 'CustomerManager',
  CrewMember: 'CrewMember',
  CrewBooker: 'CrewBooker',
  CrewSelfBooker: 'CrewSelfBooker'
};

export const RoleNameString = {
  OrganizationAdmin: 'Organization Admin',
  BookingManager: 'Booking Manager',
  CustomerAdmin: 'Org Admin/Manager',
  CustomerManager: 'Booking Manager',
  CrewMember: 'Crew Member',
  CrewBooker: 'Crew Booker',
  CrewSelfBooker: 'Crew Self-Booker'
};

export default RoleName;
