import styled from 'styled-components';
import { fonts, media, mixins } from '../../../css';
import Base from '../Base/Base';

// eslint-disable-next-line
export const StyledHeadingFourth = styled(Base)`
  font-family: ${fonts.fontBold};
  font-size: ${props => mixins.pxToRem(props.fontSize)};
  line-height: ${mixins.pxToRem(28)};
  letter-spacing: 0;
  margin: ${props => (props.noMargins === false ? `0 0 ${mixins.pxToRem(8)} 0` : 0)};
  text-align: ${props => props.align};
  text-transform: ${props => props.textTransform};
  color: ${props => props.color};

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
  ${media.greaterThan('lg')`
    letter-spacing: -0.25px;
  `};
`;
